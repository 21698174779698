.image_size {
    height: 122px !important;
    margin-left: 2rem !important;
    max-width: 100% !important;
}

.brdr_clr {
    font-size: 15px;
    border: 1px solid #CDCDCD;
    width: 100%;
    border-radius: 5px;
    background-color: white;
    padding: 5px;
}

@media (max-width:767px) {
    .image_size {
        display: block;
        margin: auto !important;
    }

    .select_card {
        width: 85%;
        margin-left: 23px;
    }

    .brdr_clr {
        margin-top: 10px;
    }
}