.questions {
    background: url('../allSVGS//Background1.svg');
    background-size: cover;
    overflow: scroll!important;
    height: 100vh;
    /* overflow: hidden; */
}

.sad,
.fair,
.happy {
    background: #F8F8F8;
    /* background-color: grey; */
    /* cursor: pointer; */
    width: 20%;
    text-align: center;
    height: 35px;
    /* position:relative; */
}

.position_align {
    position: relative;
    z-index: 111 !important;
}

.emoji {
    cursor: pointer;
}

/* .emoji:hover+.data, */
.newdata {
    min-height: 35px;
    background-color: #0693A5;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 2px;
    max-height: 35px;
    height: 35px;
    border-radius: 6px;
}

/* .newemoji + span {
    min-height: 35px;
    background-color: #0693A5;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 2px;
    max-height: 35px;
    height: 35px;
    border-radius: 6px;
} */

input[type=radio] {
    display: none;
}

/* .sad:hover,
.fair:hover,
.happy:hover {
    background: #0693A5;
    border-radius: 5px;

} */

.rating_screen {
    padding: 0rem 0rem 0rem 3rem;
}

/* .emojiMove, */
.newemoji {
    width: 50%;
    height: 40px;
    position: absolute;
    top: -20px;
    left: 28%;
}


.sad_emoji {
    color: #FF6D63;
    /* margin-left: 12px; */
    margin-top: 10px;
    font-weight: bold;
}

.fair_emoji {
    color: #F7CF56;
    /* margin-left: 12px; */
    margin-top: 10px;
    font-weight: bold;
}

.happy_emoji {
    color: #8EE41E;
    /* margin-left: 12px; */
    margin-top: 10px;
    font-weight: bold;
}

.emoji_review {
    display: flex;
    justify-content: space-around;
    margin: 1rem;
    /* background-color: #F8F8F8; */
    /* height: 35px; */

}

.question_size1 {
    font-size: 21px;
    font-weight: 500;
}

.card_responsive {
    margin-top: 1.5rem;
}
.Qs_scroll{
    overflow: hidden;
    
}
.Qs_scroll ::-webkit-scrollbar-thumb {
    display: none !important;
}

@media (max-width:767px) {

    .sad,
    .fair,
    .happy {
        width: 31%;
    }

    .sad_emoji,
    .happy_emoji,
    .fair_emoji {
        /* margin-right: 9px; */
        white-space: nowrap;
    }
    .arabic_ques{
        margin-top: 4rem;
    }


    .arabic_ques{
        margin-top: 4rem;
   }
    /* .emojiMove,
    .newemoji {
        width: 40px;
        height: 40px;
        position: absolute;
        top: -17px;
        left: 21px;
    } */

    .emoji_review {
        display: flex;
        justify-content: space-around;
        margin-left: 0;
        margin-right: 0;
    }

    .questions {
        overflow-y: scroll;
    }

    /* .emoji {
        margin-right: 6px;
    } */
}

@media (min-width:768px) and (max-width:1024px) {

    /* .emojiMove,
    .newemoji {
        width: 40px;
        height: 40px;
        position: absolute;
        top: -17px;
        left: 53;
    } */

    .emoji_review {
        display: flex;
        justify-content: space-around;
    }
}

@media (min-width: 1440px) and (max-width: 2560px) {

    /* .emojiMove,
    .newemoji {
        width: 40px;
        height: 40px;
        position: absolute;
        top: -20px;
        left: 5.5rem;
    } */
}

@media screen and (max-width: 800px) and (orientation:landscape) {
    .questions {
        overflow: scroll;
    }
}

/* @media (min-width: 1824px) and (max-width: 2736px) {
    .emoji {
        width: 45px;
        height: 45px;
    }

    .sad,
    .fair,
    .happy {
        height: 50px;
    }

    .sad_emoji,
    .happy_emoji,
    .fair_emoji {
        font-size: 20px;
    }

    .question_size1 {
        margin-top: 2%;
    }


    .emojiMove,
    .newemoji {
        left: 45%;
    }

    .emoji_review {
        margin-top: 2rem;
    }

    .direction_footer {
        padding-top: 4.5rem !important;
    }
} */