.btdr_align {
    border: 1px solid #CDCDCD;
    border-radius: 6px;
    width: 100%;
    height: 35px;
}

.department_title {
    display: flex;
    align-items: center;
}


.img_size {
    height: 122px !important;
    margin-left: 2rem !important;
    max-width: 100% !important;

}

.brdr_clr {
    border: 1px solid #CDCDCD;
    border-radius: 5px;
    width: 100%;
}

.pass_input {
    position: relative;
}

.fa_eye {
    position: absolute;
    right: 25px;
    top: 10px;
}

.deprt_brdr {
    border: 2px solid #0693A5;
    padding: 6px 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.depart2 {
    border: 2px solid #0693A5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
}

@media (max-width:767px) {
    .depart2 {
        font-size: 21px;
        margin-top: 10px;
    }

    .img_size {
        display: block;
        margin: auto !important;
    }

    .login_responsive {
        width: 90%;
        margin-left: 20px;
    }

    .fa_eye {
        top: 20px;
    }
}