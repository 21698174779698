.question1 {
    background: url('../allSVGS/Background3.svg');
    height: 100vh;
    background-position: 100% 100%;
    background-repeat: no-repeat;
}


.text {
    border-radius: 5px;
    border: 2px solid rgb(235, 230, 230);
    width: 90%;

}

.rise_btn {
    background-color: #0693A5!important;
    border-color: #0693A5 !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    display: block;
    margin: auto;
    display: flex!important;
    align-items: center !important;
    justify-content: center !important;
}

.rise_btn:hover {
    background-color: white!important;
    color: #0693A5 !important;
}

/* .head_dept {
    font-weight: 700;
} */

.question_size {
    font-size: 18px;
    font-weight: 500;
}

/* .question1 {
    overflow: hidden;
} */

.card_respo {
    margin-top: 1.5rem;
}

@media (max-width:767px) {
    .head_dept {
        font-size: 19px;
    }

    .quat_size {
        font-size: 13px;
    }

    /* .question1 {
        overflow-y: scroll;
    } */

    .head_dept {
        text-align: center;
    }

    .card_align {
        margin-left: 9px;
    }


}

@media screen and (max-width: 800px) and (orientation:landscape) {
    .question1 {
        overflow: scroll;
    }
}