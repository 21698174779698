.edit_department {
    margin-top: 5rem;
    text-align: center;
    font-size: 24px;
    color: #0693A5;
    font-weight: bold;
}

.modal-dialog {
    margin-top: 9rem;
}

.modal-content {
    background-color: transparent;
    border: 0;
}

.admin,
.depart {
    margin: 0px !important
}
