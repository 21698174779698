.App {
  color: #0693a5;
}

@font-face {
  font-family: "Lights";
  src: local("Barlow-Regular"), url(./fonts/Barlow/Barlow-Regular.ttf);
}

body {
  font-family: "Lights", Arial, sans-serif !important;
}

.homescreen {
  background-repeat: no-repeat;
  background-size: cover;
  /* height: calc(var(--vh, 1vh) * 100); */
  min-height: 100vh;
  cursor: pointer;
}

.tap {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.tap_text {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  bottom: -15px;
  left: 30%;
  color: black;
  white-space: nowrap;
}
.icons_sty {
  cursor: pointer;
}

::selection {
  color: white;
  background: #0693a5;
}

.textAreascroll {
  white-space: normal !important;
  overflow-wrap: break-word !important;
  resize: none !important;
  overflow-y: scroll !important;
}
.css-50etie svg circle {
  stroke: #3498db !important;
}

@media (max-width: 767px) {
  .tap {
    bottom: 12%;
    right: 0%;
  }
  /* .mobileHomescreen {
    overflow-y: hidden !important;
    min-height: 100vh !important;
  } */
}

@media (min-width: 1440px) and (max-width: 2560px) {
  h1,
  h2 {
    font-size: 60px !important;
  }

  h3,
  h4 {
    font-size: 42px !important;
  }

  p {
    font-size: 24px !important;
  }

  p.error {
    font-size: 20px !important;
    font-weight: 400;
  }

  label {
    font-size: 18px !important;
  }

  .card_respo {
    margin-top: 5rem !important;
  }

  .card_responsive {
    margin-top: 5rem !important;
  }

  .rating_screen {
    padding: 3rem 2rem;
  }

  .card_responsive {
    width: auto;
    height: 60% !important;
    display: flex;
    overflow: scroll;
  }

  .card_respo {
    width: 100%;
    min-height: 60%;
    display: flex;
  }

  .card_width {
    min-height: 540px;
  }

  .card1 {
    height: inherit;
  }

  .card2 {
    height: 100%;
  }

  .card3 {
    height: inherit;
  }

  .footer_direction {
    margin-top: 5% !important;
  }

  .footer_direction1 {
    margin-top: 3% !important;
  }

  .emoji_review {
    margin: 2rem !important;
  }

  .iframe_review {
    height: 460px !important;
    padding: 0;
  }

  .position_page {
    margin-top: 6rem;
  }

  .textareas {
    width: 60%;
    height: 125%;
  }

  .modal-content {
    margin-top: 19rem;
  }

  .fa_eye {
    position: absolute;
    right: 25px;
    top: 6px;
  }
}
