.first {
  background-image: url("../allSVGS//Background1.svg");
  background-size: cover;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 100%;
}

.image {
  display: flex;
  align-items: center;
}

.image1 {
  height: 122px !important;
  margin-left: 2rem;
  max-width: 100%;
}

.department {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3rem ;
}

.department1 {
  color: #0693a5;
  border: 2px solid #0693a5 !important;
  border-radius: 10px;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.space_between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.language_margin {
  padding-bottom: 5%;
}

.list {
  list-style-type: none;
  text-align: left;
  padding-top: 7% !important;
}

.lang_btn {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  background-color: white;
  color: #0693a5 !important;
  width: 120px !important;
  font-weight: 600 !important;
}

.lang_btn1 {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  border: 2px solid #0693a5 !important;
  background-color: white;
  color: #0693a5 !important;
  font-weight: 600 !important;
  width: fit-content !important;
}

.lang_btn:hover {
  background-color: #0693a5;
  color: white !important;
}

@media screen and (max-width: 768) {
  .first {
    background-size: contain;
  }
}

@media (max-width: 767px) {
  .image1 {
    display: block;
  }
  .space_between {
    justify-content: space-around;
  }

  .department_name {
    font-size: 23px;
  }

  .dept_margin {
    padding-left: 0rem;
  }

  .department1 {
    padding-top: 1rem;
  }

  .language {
    padding-top: 2rem;
  }

  .lang_btn {
    width: 100%;
  }

  .department_size {
    font-size: 23px;
  }

  .department {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0px !important;
    padding-bottom: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .lang_btn {
    width: 100%;
  }

  .department_size {
    font-size: 23px;
  }
}

@media (min-width: 1440px) and (max-width: 2560px) {
  ul.list {
    margin-top: 10% !important;
  }
  .language {
    margin-top: 10rem;
  }
}

/* @media (min-width: 1824px) and (max-width: 2736px) {


    .language {
        margin-top: 4%;
    }
} */
