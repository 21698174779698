/* sprlogin styles */
.card-sty{
    justify-content: center !important;
    align-items: center!important;
    display: flex !important;
}
.adjest{
  margin:20% auto;
}


.btdr_align {
    border: 1px solid #CDCDCD;
    border-radius: 6px;
    width: 100%;
    height: 35px;
}
.forgot_label {
  color: #0693A5;
  font-weight: 800; 
  cursor: pointer;
}

.department_title {
    display: flex;
    align-items: center;
}

.lgin-header{
    cursor: default;
}

.img_size {
    height: 122px !important;
    margin-left: 2rem !important;
    max-width: 100% !important;

}
.text-center img{
    cursor:pointer;
}

.brdr_clr {
    border: 1px solid #CDCDCD;
    border-radius: 5px;
    width: 100%;
}

.fa_eye {
    position: absolute;
    right: 25px!important;
    top: 10px;
}

.pass_input {
    position: relative;
}



.deprt_brdr {
    border: 2px solid #0693A5;
    padding: 6px 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.depart2 {
    border: 2px solid #0693A5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
}
.adjest{
  margin-top: 6rem!important  ;
}
/* .ResetAdject{
  margin-top: 6rem!important  ;
} */

@media (max-width:767px) {
    .depart2 {
        font-size: 21px;
        margin-top: 10px;
    }

    .img_size {
        display: block;
        margin: auto !important;
    }

    .login_responsive {
        width: 90%;
        margin-left: 20px;
    }

    .fa_eye {
        top: 20px;
    }
}
._loading_overlay_content {
  top: 50%;
  left: 50%;
  position: fixed;
  color: #3498db;
}


.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 1440px) and (max-width: 2560px) {

    h1,
    h2 {
      font-size: 60px !important;
    }
  
    h3,
    h4 {
      font-size: 42px !important;
    }
  
    p {
      font-size: 24px !important;
    }
  
    p.error {
      font-size: 20px !important;
      font-weight: 400;
    }
  
    label {
      font-size: 18px !important;
    }
  
    /* .card_respo {
      
    } */
  
    .card_responsive {
      margin-top: 5rem !important;
    }
  
    .rating_screen {
      padding: 3rem 2rem;
    }
  
    .card_responsive {
      width: auto;
      height: fit-content!important;
      display: flex;
    }
  
    .card_respo {
      width: 100%;
      height: fit-content!important;
      display: flex;
      margin-top: 5rem !important;
    }
   
  
    .card_width {
      min-height: 540px;
    }
  .adjest{
    margin-top: 7rem!important;
    height: 30%;
  }
  .ResetAdject{
    margin-top: 7rem!important;
    height: 40%;
  }


    /* .card1 {
      height: inherit;
    }
  
    .card2 {
      height: inherit;
    }
  
    .card3 {
      height: inherit;
    } */
  
    .footer_direction {
      margin-top: 5% !important;
    }
  
    .footer_direction1 {
      margin-top: 3% !important;
    }
  
    .emoji_review {
      margin: 2rem !important;
    }
  
    .iframe_review {
      height: 460px !important;
      padding: 0;
    }
  
    .position_page {
      margin-top: 6rem;
    }
  
    .textareas {
      width: 60%;
      height: 125%;
    }
  
    .modal-content {
      margin-top: 19rem;
    }
  
    .fa_eye {
      position: absolute;
      right: 25px;
      top: 6px;
    }
  }
  .errormessage {
    text-align: left;
    color: red;
    font-size: 13px;
  }
.errormessage1{
    display: none;
}