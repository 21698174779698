.sign-in{
    height: 60px;
    width: 60px;
}
.header{
    display:flex;
    align-items: center;
}
/* .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
  }
   */
  .modal-content {
    background-color: white;
    padding: 20px;
    /* margin: 15% auto;
    padding: 10px;
    border: 1px solid #888;
    width: 80%; */
  }
  
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .button_sty{
    background-color: #18A7E2;  
    margin: 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
  }

  .modal-data{
    justify-content: center;
text-align: center;
   
  }
.dashico{
  background: url('../../allSVGS/dashboard.svg');
}
.background-scrol{
  background-size: cover;
}
.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

/* .avatar-container img{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}.button-container{
  display: flex;
  justify-content: space-between;
} */
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin: 10px 0px ;
}

.btn_upload {
  border: none;
  color: white;
  background-color: #18A7E2!important;
  /* padding: 8px 14px!important; */
  border-radius:5px!important;
  font-size: 14px;
  font-weight: 500;
  color: white !important;
  width: 7rem;
  height: 2rem;

}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;

}