* {
  text-decoration: none;
}

@font-face {
  font-family: "Helvitica";
  src: local("helvitica"), url(/src/fonts/Helvetica.ttf);
}

.sidebar {
  position: relative;
  background-color: #18a7e2;
  border-radius: 5px;
  transition: all 0.5s;
  width: 55px;
  padding-top: 15px;
}

.main {
  padding: 20%;
}

#link {
  display: flex;
  text-decoration: none;
  font-family: "Helvitica";
  align-items: center;
  padding: 15px 15px;
  transition: all 0.9s;
  margin-top: 10px;
}

a {
  text-decoration: none;
}

.link-active {
  text-decoration: none;
  background-color: white;
  transition: all 0.5s;
  color: #18a7e2;
  position: inherit;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 5px 5px 0px;
}
.link-active img {
  filter: invert(51%) sepia(76%) saturate(1163%) hue-rotate(162deg)
    brightness(95%) contrast(87%);
}
.normalLink:hover img {
  filter: invert(51%) sepia(76%) saturate(1163%) hue-rotate(162deg)
    brightness(95%) contrast(87%);
}
.imageAdjust {
  display: flex;
  align-items: center;
  width: 100px;
  position: relative;
}
.normalLink.navText {
  display: none !important;
}

.normalLink:hover .imageAdjust :last-child {
  display: block;
  color: #18a7e2;
}
.normalLink:hover {
  text-decoration: none;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 5px 5px 0px;
}

.active {
  background-color: white;
  transition: all 0.5s;
  color: #18a7e2;
}

.text_name {
  margin-left: 20px;
}

.active img {
  filter: invert(51%) sepia(76%) saturate(1163%) hue-rotate(162deg)
    brightness(95%) contrast(87%) !important;
  fill: #18a7e2 !important;
}

.active :last-child {
  display: block !important;
  /* filter: invert(51%) sepia(76%) saturate(1163%) hue-rotate(162deg) brightness(95%) contrast(87%); */
  /*commented the filter property as it is affecting the table pagination. actually kept for sidebar icons styling while hover*/
}

.sidenavAlign {
  /* min-height: calc(100vh - 100px); */
  position: relative !important;
}

.navi {
  height: calc(100vh - 100px);
  position: absolute !important;
  top: 0;
  left: 12px;
  background-color: #18a7e2 !important;
  border-radius: 5px;
}

.infoi {
  min-height: calc(100vh - 100px);
  position: absolute !important;
  top: 0;
  left: 10px;
  z-index: 10;
}
li a {
  display: block !important;
}
.navText {
  color: #4d4d4d;
  font-family: "Helvitica";
  display: none;
}
.navText:hover {
  display: block;
  font-family: "Helvitica";
}

.link-active .navText {
  display: block !important;
  font-family: "Helvitica" !important;
  color: #18a7e2;
}

.listType:hover .navText {
  display: block;
  color: #18a7e2;
  font-family: "Helvitica" !important;
}
.listType {
  margin-top: 10px;
}

.svgIcon {
  width: 51px;
  height: 50px;
  padding: 11px 15px;
}
.listType:hover {
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 5px 5px 0px;
}

.listType:hover .svgIcon img.icon-menu {
  filter: invert(50%) sepia(74%) saturate(647%) hue-rotate(153deg)
    brightness(99%) contrast(85%) !important;
}
