.thanks {
    background: url('../allSVGS/Background2.svg');
    height: 100vh;
    background-size: cover;
    overflow: hidden;
}

.gif {
    max-width: 100%;
    height: 300px
}

.thank_footer {
    /* position: absolute; */
    font-size: 25px;
    font-weight: bold;
    margin-top: -3rem;
}

.position_page {
    text-align: center;
}

.card_respo {
    margin-top: 1.5rem;
}

@media (max-width:767px) {
    .thanks {
        overflow-y: scroll;
    }
}

@media screen and (max-width: 800px) and (orientation:landscape) {
    .thanks {
        overflow: scroll;
    }
}

/* @media (min-width: 1824px) and (max-width: 2736px) {
    .surface_image {
        margin-top: 4rem !important;
    }
} */