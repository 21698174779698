@font-face {
  font-family: "Helvitica";
  src: local("helvitica"), url(/src/fonts/Helvetica.ttf);
}

.db-sty {
  border-radius: 20px !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.spanHos {
  color: #0693a5;
}

.db-card {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 20px;
}
/* Spr hospital styles */
.Add-admins {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-wrap: wrap;
}
.search_btn {
  padding: 0em !important;
}
/* .btn {
     margin: 0px!important;
 } */
.btn.btn-sm {
  padding: 0.5rem 0.6rem !important;
  font-size: 0.79rem;
  background-color: #18a7e2 !important;
  width: 8rem;
  height: 2.3rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 0.78rem !important;
  border: none;
}
.exportitems {
  align-items: center;
  padding: 0.4rem 0.9rem !important;
  border-radius: 3px !important;
  width: 130px;
  height: 35px !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  color: #464646 !important;
  justify-content: space-between;
}
.input-width {
  width: 15rem !important;
}
.table_text {
  align-items: center;
}

.add-modal {
  justify-content: center;
  align-items: center;
}
.modal_titles {
  margin-top: 10px;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  color: #18a7e2;
}
.closeButtons {
  margin-top: -1rem !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: 900 !important;
}
.upload_sty {
  border: none;
  font-family: "Helvitica";
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.25);
  background-color: #ffffff !important;
  color: #000000;
}
Modal.Header .btn-close {
  color: #18a7e2 !important;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -25px -26px -25px auto;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.modal-content {
  border-radius: 20px !important;
}
.label-sty {
  font-size: 15px !important;
  white-space: nowrap;
  font-weight: 400;
}
.add_btn {
  color: white !important;
  background-color: #18a7e2 !important;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}
/* .modal-dialog .modal-content {
     width: 41rem;
     height: 30rem;
 } */
.modal_footer2 {
  border: none !important;
  text-align: center !important;
  justify-content: center !important;
  display: flex !important;
}
.modal-btns {
  text-decoration: none !important;
  background-color: red !important;
}

.admin-card {
  height: 259px;
  width: 287px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000040;
  padding: 20px;
  display: flex;
  align-items: center;
}

.form_drop {
  height: 35px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
}
.dropdown_sty {
  height: 3rem !important;
}
.form_head {
  position: relative;
}

.search_icon {
  position: absolute;
  top: 12px;
  right: 9px;
}
.pdf_icon {
  font-size: 24px;
  color: #ac0f0f;
  cursor: pointer;
}
.excel_icon {
  color: #00733b;
  cursor: pointer;
  font-size: 24px;
}
.sd-title {
  color: #18a7e2;
  font-family: "Helvitica";
}

.count {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.count_no {
  font-size: 15px;
  color: #464646;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
}

.recharts-legend-item-text {
  display: none;
}
.recharts-legend-icon {
  display: none;
}
.page-item.active {
  background: #18a7e2 !important;
  border-color: #18a7e2 !important;
  color: #ffffff !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #18a7e2;
  border-color: #18a7e2;
}

.table_body {
  color: #464646 !important;
  font-weight: 500 !important;
  font-family: "Barlow";
}
.table_text {
  justify-content: center !important;
  text-align: center;
  color: #464646 !important;
  font-family: "Barlow";
  font-weight: 700 !important;
  font-size: 14px;
}


.table-bordered {
  border-radius: 20px !important;
  box-sizing: border-box;
  border: 1px solid #bbbbbb;
}
th {
  border: 1px solid #bbbbbb !important;
  color: #464646 !important;
}
.swal2-modal .swal2-actions .swal2-styled.swal2-cancel {
  background-color: #fe7c96 !important;
}
.Admins-Count {
  font-family: "Helvitica";
  font-weight: 700 !important;
}

.swal2-modal .swal2-actions .swal2-styled.swal2-confirm {
  background-color: #18a7e2 !important;
}
