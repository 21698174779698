.userdetails {
    background: url('../allSVGS//Background1.svg');
    background-size: cover;
    height: 100vh;
    font-weight: 500;
    box-sizing: border-box !important;
    padding: 0px !important;
    padding-top: 1rem !important;
    max-width: 100%!important;
    overflow-x: hidden;

}

.user_footer {
    padding-bottom: 1.5rem;
}

.error {
    color: red;
    font-size: 12px;
}


.image {
    padding: 0px !important;
}

.image1 {
    height: 122px !important;
    /* margin-left: 2rem; */
    max-width: 100%;
    padding: 0px !important
}


.back_img {
    filter: invert(65%) sepia(75%) saturate(5968%) hue-rotate(167deg) brightness(101%) contrast(95%);
    cursor: pointer;
}

.back_img1 {
    cursor: pointer;
}

.deparment-title {
    font-weight: bold;
}

.header {
    font-weight: 700;
}

.department2 {

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    padding: 0px !important;
    cursor:none
}

.department2 button:not(:disabled){
    cursor: none !important;
}
.card_respo {
    width: 100%;
}

.card_responsive {
    width: 100%
}

.card1,
.card2,
.card3 {
    width: 100% !important;
    padding: 0px;
    border-radius: 20px;
}

.card1 {
    transform: rotate(6deg);
    background-color: #CECBCB!important;
}

.card2 {
    transform: rotate(-3deg);
    background-color: #E4E2E2!important;
}

.card3 {
    transform: rotate(-3deg)
}

.input_brdr {
    border: 1px solid #CDCDCD;
    border-radius: 5px;
    height: 35px;
    width: 100%;
    text-indent: 5px;
}

.footer {
    text-align: center;
}

form {
    padding: 1rem;

}

.input_pad {
    padding-right: 4px;
}

.input_space {
    padding-left: 0;
}
.surface_header{
    align-items: center;
    justify-content: center;
    display: flex;
}

@media (max-width:767px) {
    .deparment-title {
        /* margin: 1rem ; */
        text-align: center;
    }

    .card_responsive {
        padding-top: 2rem;
    }

    /* .card_respo {
        padding: 2rem 0rem 0rem 0rem;
    } */
    .card_width {
        width: 85%;
        margin-left: 26px;
    }

    .rating_screen {
        padding: 0rem !important;
    }

    .dNone {
        display: none;
    }

    .footer {
        margin-top: 2rem;
    }

    .department2 {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .userdetails {
        overflow-y: scroll;
    }

}

@media(min-width:768px) and (max-width:1024px) {
    .card_respo {
        margin-top: 3rem;
        padding: 2rem;
    }
}

@media screen and (max-width: 800px) and (orientation:landscape) {
    .userdetails {
        overflow: scroll;
    }
}

/* @media (min-width: 1668px) and (max-width: 2366px) {
    .userdetails {
        padding-top: 5rem !important;
    }

    .card_respo {
        margin-top: 20rem !important;
    }
} */
/* @media only screen and (min-width : 1824px) {
    .surface_header {
        margin-top: 4% !important;
    }

    .surface-body {
        margin-top: 3% !important;
    }

    .user_footer {
        margin-top: 6% !important;
    }

    .surface_card {
        height: 800px;
    }

    .surface_input {
        margin-top: 2%;
    }

    .surface_cardtop {
        margin-top: 5rem !important;
    }

} */

/* @media all and (max-width: 2736px) and (min-width: 1824px) {


    .surface_header {
        margin-top: 4% !important;
    }

    .surface-body {
        margin-top: 3% !important;
    }

    .user_footer {
        margin-top: 6% !important;
    }

    .surface_card {
        height: 800px;
    }

    .surface_input {
        margin-top: 2%;
    }

    .surface_cardtop {
        margin-top: 5rem !important;
    }

} */