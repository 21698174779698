.complaint {
    background: url('../allSVGS//Background2.svg');
    height: 100vh;
    background-size: cover;
    overflow: hidden;
}

textarea {
    border-radius: 10px;
    max-width: 100%;
    border: 1px solid #CDCDCD;
    padding: 10px;
}

.comp_dept {
    font-weight: 700;
}

@media (max-width:767px) {
    .complaint_title {
        margin-bottom: 10px;
    }

    textarea {
        max-width: 90%;
    }

    .complaint {
        overflow-y: scroll;
    }

}

@media (min-width:768px) and (max-width:1024px) {
    textarea {
        max-width: 90%;
    }

    .card_responsive {
        margin-top: 5rem !important;
    }
}

@media screen and (max-width: 800px) and (orientation:landscape) {
    .complaint {
        overflow: scroll;
    }
}